import Carousel from './modules/carousel';
import Gallery from './modules/gallery';
import Global from './modules/global';
import Cmp from './modules/cmp';
import Commercial from './modules/commercial';
import Map from './modules/map';
import Newsletter from './modules/newsletter';
import Multiplex from './modules/multiplex';
import Livenow from './modules/livenow';
import ScrollTracker from './modules/scrolltracker';

const modules = {
  Carousel,
  Gallery,
  Global,
  Cmp,
  Commercial,
  Map,
  Newsletter,
  Multiplex,
  Livenow,
  ScrollTracker
};

var elements = document.querySelectorAll('.joomla-script-options');
if (elements.length > 0) {
  var options = JSON.parse(elements[0].text || elements[0].textContent);
  options.modules.forEach((module) => {
    if (modules[module.name]) {
      new modules[module.name](module.options);
    }
  });
}
