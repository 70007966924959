import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

class Gallery {
  constructor(options) {

    var defaults = {
      title: '',
      link: '',
      adSlot: '',
      inlineAdSlot: '',
      containerSelector: '.story-photo-gallery',
      modalSelector: '#modal',
      imageSelector: 'img',
      captionSelector: '.photo-description',
      indexSelector: '.photo-num',
      previousButtonSelector: '.previous-button',
      nextButtonSelector: '.next-button',
      closeButtonSelector: '.close-modal',
      triggerSelector: '.open-modal',
      itemsContainerSelector: '.gallery-items',
      itemsSelector: '.gallery-item',
      itemsIndexingSelector: '.indexing',
      swipeNextSelector: '.navigate-next',
      swipePreviousSelector: '.navigate-prev'
    };

    this.params = Object.assign({}, defaults, options || {});

    this.modal = document.querySelector(this.params.modalSelector);
    this.image = this.modal.querySelector(this.params.imageSelector);
    this.caption = this.modal.querySelector(this.params.captionSelector);
    this.index = this.modal.querySelector(this.params.indexSelector);
    this.previousButton = this.modal.querySelector(this.params.previousButtonSelector);
    this.nextButton = this.modal.querySelector(this.params.nextButtonSelector);
    this.closeButton = this.modal.querySelector(this.params.closeButtonSelector);
    this.container = document.querySelector(this.params.containerSelector);
    this.triggers = this.container.querySelectorAll(this.params.triggerSelector);

    this.images = this.params.images;
    this.active = 0;
    this.total = this.images.length;
    this.state = 0;
    this.scroll = 0;
    this.modalBanner = document.getElementById('gallery-banner');

    this.addEvents();
  }

  addEvents() {

    window.addEventListener('keydown', (event) => this.onKeyDown(event));
    window.addEventListener('scroll', (event) => this.onScroll(event));

    if (this.closeButton) {
      this.closeButton.addEventListener('click', (event) => {
        event.preventDefault();
        this.hide();
      });
    }

    if (this.previousButton) {
      this.previousButton.addEventListener('click', (event) => this.previous(event, 'previous'));
    }

    if (this.nextButton) {
      this.nextButton.addEventListener('click', (event) => this.next(event, 'next'));
    }

    for (var index = 0; index < this.triggers.length; index++) {
      this.addTriggerEvent(index);
    }

    //this.addTouchEvents();
  }

  addTriggerEvent(index) {
    this.triggers[index].addEventListener('click', (event) => {
      event.preventDefault();
      this.active = 0;
      this.show();
      this.update();
    });
  }

  show() {
    this.modal.classList.add('visible-modal');
    this.state = 1;
    this.scroll = window.pageYOffset;
  }

  hide() {
    this.modal.classList.remove('visible-modal');
    this.state = 0;
    this.reset();
  }

  reset() {
    this.image.src = '';
    this.image.alt = '';
    this.caption.innerHTML = '';
  }

  onKeyDown(event) {
    if (!this.state) {
      return;
    }
    if (event.keyCode == 37) {
      this.previous();
    } else if (event.keyCode == 39) {
      this.next();
    } else if (event.keyCode == 27) {
      this.hide();
    }
  }

  onScroll(event) {
    if (this.state) {
      window.scrollTo(0, this.scroll);
    }
  }

  next() {
    if (!this.state) {
      return;
    }
    if (this.active + 1 >= this.total) {
      this.active = 0;
    } else {
      this.active++;
    }
    this.update();
  }

  previous() {
    if (!this.state) {
      return;
    }
    if (this.active - 1 < 0) {
      this.active = this.total - 1;
    } else {
      this.active--;
    }
    this.update();
  }

  update() {
    var active = this.images[this.active];
    this.image.src = active.img_main;
    this.image.alt = active.title;
    let html = active.description;
    if (active.source) {
      html += '<cite>' + active.source + '</cite>';
    }
    this.caption.innerHTML = html;
    let num = this.active + 1;
    this.index.textContent = num < 10 ? '0' + num : num;
    this.trackPageView();
    if (window.googletag) {
      this.showAd();
    }
  }

  showAd() {
    if (!window.newsGalleryAdSlot) {
      googletag.cmd.push(() => {
        window.newsGalleryAdSlot = googletag.defineSlot(this.params.adSlot, [
          [300, 250],
          [300, 600]
        ], 'gallery-banner').addService(googletag.pubads());
      });
      googletag.display('gallery-banner');
    } else {
      this.modalBanner.removeAttribute('lazyloaded-by-ocm');
      //googletag.pubads().refresh([window.newsGalleryAdSlot]);
    }
  }

  trackPageView() {
    if (window.ga) {
      ga('set', {
        page: this.params.link,
        title: this.params.title
      });
      ga('send', 'pageview');
    }

    if (window.AT_hit) {
      window.AT_hit.sendTag("F");
    }
  }

  addTouchEvents() {

    this.itemsContainer = this.container.querySelector(this.params.itemsContainerSelector);
    this.items = this.container.querySelectorAll(this.params.itemsSelector);
    this.indexing = this.container.querySelector(this.params.itemsIndexingSelector);
    this.swipeNextButton = this.container.querySelector(this.params.swipeNextSelector);
    this.swipePreviousButton = this.container.querySelector(this.params.swipePreviousSelector);

    this.activeItem = 0;
    this.swiping = false;
    this.startXOffset = null;
    this.startYOffset = null;

    this.itemsContainer.addEventListener('touchstart', (event) => {
      if (this.swiping) {
        return;
      }
      this.startXOffset = event.touches[0].clientX;
      this.startYOffset = event.touches[0].clientY;
    }, false);

    this.itemsContainer.addEventListener('touchmove', (event) => {
      if (this.swiping) {
        return;
      }
      if (this.startXOffset === null) {
        return;
      }
      if (this.startYOffset === null) {
        return;
      }
      var currentXOffset = event.touches[0].clientX;
      var currentYOffset = event.touches[0].clientY;
      var xDifference = this.startXOffset - currentXOffset;
      var yDifference = this.startYOffset - currentYOffset;
      if (Math.abs(xDifference) > Math.abs(yDifference) && (Math.abs(xDifference) - Math.abs(yDifference) > 20)) {
        this.swiping = true;
        if (xDifference > 0) {
          this.swipe('next');
        } else {
          this.swipe('previous');
        }
        setTimeout(() => {
          this.swiping = false;
        }, 500);
      }
    }, false);

    if (this.swipeNextButton) {
      this.swipeNextButton.addEventListener('click', (event) => this.swipe('next'));
    }
    if (this.swipePreviousButton) {
      this.swipePreviousButton.addEventListener('click', (event) => this.swipe('previous'));
    }

  }

  swipe(direction) {

    var index = this.activeItem;

    if (direction === 'next') {
      index++;
    } else {
      index--;
    }

    if (index < 0) {
      return;
    }

    if (index > this.items.length - 1) {
      return;
    }

    var active = this.items[index];
    var offset = active.getBoundingClientRect().left + this.itemsContainer.scrollLeft;

    this.itemsContainer.scroll({
      top: 0,
      left: offset,
      behavior: 'smooth'
    });

    this.trackPageView();

    if (active.classList.contains('gallery-inline-banner')) {
      this.indexing.innerHTML = '';
      this.showInlineAd(active);
    } else {
      this.indexing.innerHTML = active.dataset.index + '/' + this.images.length;
      this.destroyInlineAd();
    }

    this.activeItem = index;


  }


  showInlineAd(element) {
    if (typeof googletag.pubads !== 'function') {
      return;
    }
    googletag.cmd.push(() => {
      window.newsGalleryInlineAdSlot = googletag.defineSlot(this.params.inlineAdSlot, [
        [300, 250]
      ], element.id).addService(googletag.pubads());
      googletag.display(element.id);
    });
  }

  destroyInlineAd() {
    if (typeof googletag.pubads !== 'function') {
      return;
    }
    if (window.newsGalleryInlineAdSlot) {
      googletag.destroySlots([window.newsGalleryInlineAdSlot]);
    }
  }

}

export {
  Gallery as
  default
};
