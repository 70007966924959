class ScrollTracker {

  constructor(options) {
    var defaults = {
      elementSelector: '#ad-position-153',
      padding: 60
    };
    this.params = Object.assign({}, defaults, options || {});
    this.element = document.querySelector(this.params.elementSelector);
    this.percentage = 0;
    this.passiveSupported = false;
    this.tracked = false;
    this.detectPassiveListenersSupport();
    this.addEvents();
  }


  detectPassiveListenersSupport() {
    try {
      const options = {
        get passive() {
          this.passiveSupported = true;
          return false;
        }
      };

      window.addEventListener("test", null, options);
      window.removeEventListener("test", null, options);
    } catch (err) {
      this.passiveSupported = false;
    }
  }

  addEvents() {

    window.addEventListener('scroll', () => {

      let percentage = Math.round((window.scrollY + this.params.padding) / (this.element.offsetTop - this.element.offsetHeight) * 100);

      if (percentage > 100) {
        percentage = 100;
      }

      if (percentage > this.percentage) {
        this.percentage = percentage;
      }

      if (this.percentage === 100 && !this.tracked) {
        this.tracked = true;
        this.track();
      }

    }, this.passiveSupported ? {
      passive: true
    } : false);

    window.addEventListener('beforeunload', (event) => {
      if (!this.tracked) {
        this.tracked = true;
        this.track();
      }
    });

  }

  track() {

    if (!window.ga) {
      return;
    }

    ga('send', 'event', 'Scroll', window.location.pathname, this.percentage, {
      'metric5': this.percentage,
      nonInteraction: true
    });
  }

}

export {
  ScrollTracker as
  default
};
