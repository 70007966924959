import axios from 'axios';
import {
  debounce
} from 'debounce';

class Map {
  constructor(options) {

    var defaults = {
      selectListSelector: '',
      mapAreaSelector: '',
      mapSvg: '',
      areaTitleSelector: '',
      areaStoriesSelector: '',
      panelSelector: '.local-news-panel',
      togglerSelector: '.panel-tool',
      resource: 'section'
    };

    this.params = Object.assign({}, defaults, options || {});
    if (this.params.mapAreaSelector) {
      this.mapArea = document.querySelector(this.params.mapAreaSelector);
    }
    this.selectList = document.querySelector(this.params.selectListSelector);
    this.areaTitle = document.querySelector(this.params.areaTitleSelector);
    this.carouselItems = document.querySelector(this.params.areaStoriesSelector);
    this.panel = document.querySelector(this.params.panelSelector);
    if (this.params.togglerSelector) {
      this.toggler = document.querySelector(this.params.togglerSelector);
    }
    this.sectionId = 0;
    this.defaultAreaTitle = this.areaTitle.textContent;
    this.mapLoaded = false;
    this.start();
  }

  start() {
    if (this.toggler) {
      this.addTogglerEvent();
    }

    this.addDropDownEvent();

    if (!this.params.mapSvg) {
      return;
    }

    if (window.innerWidth >= 768) {
      this.loadMap();
    }

    window.addEventListener('resize', debounce((event) => {
      if (!this.mapLoaded && window.innerWidth >= 1024) {
        this.loadMap();
      }
    }, 100));

  }

  loadMap() {
    this.mapLoaded = true;
    return axios.get(this.params.mapSvg)
      .then((response) => {
        this.mapArea.innerHTML = response.data;
        this.links = document.querySelectorAll(this.params.mapAreaSelector + ' a');
        this.links.forEach((link, index) => {
          this.addLinkOnClickEvent(link, index);
        });
      });
  }

  addDropDownEvent() {
    this.selectList.addEventListener('change', (e) => {
      this.onSelectListChange();
    });
  }

  addLinkOnClickEvent(link, index) {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      this.sectionId = link.getAttribute('xlink:href');
      if (this.activeLink) {
        this.activeLink.removeAttribute('class');
      }
      link.setAttribute('class', 'active');
      this.activeLink = link;
      this.fetchItems();
    });
  }

  addTogglerEvent() {
    this.toggler.addEventListener('click', (e) => {
      e.preventDefault();
      this.panel.classList.toggle('collapsed');
    });
  }

  fetchItems() {
    this.carouselItems.classList.add('loading');
    axios.get('/index.php?option=com_news&view=' + this.params.resource + '&id=' + this.sectionId + '&format=raw&layout=map')
      .then((response) => {
        this.carouselItems.classList.remove('loading');
        this.panel.classList.remove('collapsed');
        this.setItems(response.data);
        this.setActiveOption();
        this.setTitle();
      });
  }

  onSelectListChange() {
    this.sectionId = this.selectList.value;
    this.fetchItems();
  }

  setActiveOption() {
    this.selectList.value = this.sectionId;
  }

  setTitle() {
    if (this.selectList.selectedIndex > 0) {
      this.title = this.selectList.options[this.selectList.selectedIndex].textContent;
    } else {
      this.title = this.defaultAreaTitle;
    }
    this.areaTitle.textContent = this.title;
  }

  setItems(data) {
    this.carouselItems.innerHTML = data;
    var event = new Event('refresh');
    this.carouselItems.dispatchEvent(event);
  }
}

export {
  Map as
    default
};
