import Tabs from './tabs.js';
import LinksTracker from './linkstracker';

class Global {
  constructor(options) {
    var defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.run();
  }

  run() {
    this.menu();
    this.tabs();
    new LinksTracker();
  }

  menu() {
    var burgers = document.querySelectorAll('.rich-menu');
    burgers.forEach((burger) => {
      burger.addEventListener('click', function(event) {
        event.preventDefault();
        this.classList.toggle('is-open');
        document.body.classList.toggle('menu-opened');
      });
    });
  }

  tabs() {
    var i, tabs = document.querySelectorAll('.tabs');
    for (i = 0; i < tabs.length; i++) {
      new Tabs({
        container: tabs[i]
      });
    }
  }

}

export {
  Global as
  default
};
